import React, { useState } from 'react'
import QuestionRenderer from './QuestionRenderer'

function SubmissionForm1 ({ questions, subtaskId, onAnswerChange }) {
  const [answers, setAnswers] = useState({})
  // console.log(questions)

  const handleAnswerChange = (questionIndex, answer) => {
    setAnswers(prevAnswers => ({
      ...prevAnswers,
      [subtaskId]: {
        ...(prevAnswers[subtaskId] || {}),
        [questionIndex]: answer
      }
    }))

    // Call the onAnswerChange prop with the subtaskId, questionIndex, and answer
    onAnswerChange(subtaskId, questionIndex, answer)
  }

  return (
    <div className='shadow-lg max-w-screen-xl p-5 my-5 flex flex-col gap-5 '>
      {questions.map((question, index) => (
        <div className='w-[300px]  md:w-[600px]'>
          <QuestionRenderer
            key={index}
            question={question}
            answer={
              (answers[subtaskId] &&
                answers[subtaskId][question.questionIndex]) ||
              ''
            }
            onAnswerChange={answer =>
              handleAnswerChange(question.questionIndex, answer)
            }
          />
        </div>
      ))}
    </div>
  )
}

export default SubmissionForm1
