import { TextField } from '@mui/material'
import React from 'react'
import './boxStyle.css'

function Paragraph ({ question, answer, onAnswerChange }) {
  const handleInputChange = event => {
    onAnswerChange(event.target.value)
  }

  return (
    <div className='bg-white shadow-lg p-4 rounded-md flex flex-col gap-2.5 md:gap-5 '>
      <h3 className='font-bold text-lg'>
        <span className='p-1.5 px-3 text-base rounded-md bg-boomcolor text-white me-2'>
          {question.questionIndex}
        </span>
        Question{' '}
      </h3>
      <h2>
        {question.questionText}
        {question.mandatory && <span className='text-red-500'> *</span>}
      </h2>
      <label htmlFor='paragraph'>{question.question}</label>
      <textarea
      rows={3}
        className='border-2  border-gray-400 rounded-lg bg-white w-full border-xl focus:border-boomcolor'
        id='paragraph'
        name='paragraph'
        value={answer}
        onChange={handleInputChange}
      />
    </div>
  )
}

export default Paragraph
