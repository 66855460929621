import React, { useEffect, useState } from "react";
import "./AppRoutes.css";
import { Route, Routes, useNavigate, useParams } from "react-router-dom";
import SideNavbar from "./SideNavbar";
import SubmissionForm1 from "./SubmissionForm1";
import axios from "axios";
import PersonalDetail from "./PersonalDetail";

import SubmissionForm2 from "./SubmissionForm2";
import AfterSubmit from "./AfterSubmit";

function AppRoutes() {
  const [subtaskInfos, setSubtaskInfos] = useState([]);
  const [currentSubtask, setCurrentSubtask] = useState(null);
  const [personalDetails, setPersonalDetails] = useState({
    name: "",
    email: "",
    phoneNumber: "",
  });
  const [subtaskAnswers, setSubtaskAnswers] = useState({});
  const [taskType, setTaskType] = useState("");
  const navigate = useNavigate();
  const [isSubmitted, setIsSubmitted] = useState(false);

  const pathSegments = window.location.pathname.split("/");
  const code = pathSegments[1];
  console.log(subtaskInfos);

  useEffect(() => {
    axios
      .post(`${process.env.REACT_APP_BASE_URL}/getLinkData`, { code })
      .then((response) => {
        setSubtaskInfos(response.data.data.subtask_infos);
        setCurrentSubtask(response.data.data.subtask_infos[0]);
        setTaskType(response.data.data.taskType);
        console.log(response.data);
      })
      .catch((error) => console.error("Error:", error));
  }, [code]);

  const handleNavItemClick = (subtaskId) => {
    const subtask = subtaskInfos.find(
      (subtask) => subtask.subtaskId === subtaskId
    );
    setCurrentSubtask(subtask);
  };

  const handlePersonalDetailChange = (newDetails) => {
    setPersonalDetails(newDetails);
  };

  const handleAnswerChange = (subtaskId, questionIndex, answer) => {
    setSubtaskAnswers((prevAnswers) => ({
      ...prevAnswers,
      [subtaskId]: {
        ...prevAnswers[subtaskId],
        [questionIndex]: answer,
      },
    }));
  };
  console.log("Updated subtaskAnswers:", subtaskAnswers);

  const handleSubmit = () => {
    if (
      (!personalDetails.name ||
        !personalDetails.email ||
        !personalDetails.phoneNumber) &&
      taskType === 1
    ) {
      // alert('Please fill all the personal details before submitting.')
      navigate(`/${code}/personal-detail`);
      return;
    }

    // Find the first subtask that is not filled
    const unfilledSubtask = subtaskInfos.find((subtaskInfo) => {
      const submissionForm = subtaskInfo.subtask_submission_form.submissionForm;
      const currentSubtaskAnswers = subtaskAnswers[subtaskInfo.subtaskId] || {};

      return !submissionForm.every(
        (question) =>
          currentSubtaskAnswers[question.questionIndex] !== undefined
      );
    });

    if (unfilledSubtask && taskType === 1) {
      // alert('Please fill all the subtask submission forms before submitting.')
      navigate(`/${code}/${unfilledSubtask.subtaskTitle.toLowerCase()}`);
      setCurrentSubtask(unfilledSubtask);
      return;
    }

    function isCurrentSubtaskFilled() {
      console.log("current subtask- ", currentSubtask);
      // Find the current subtask information
      const currentSubtaskInfo = subtaskInfos.find(
        (subtaskInfo) => subtaskInfo.subtaskId === currentSubtask.subtaskId
      );
      if (!currentSubtaskInfo) {
        return false;
      }

      // Retrieve the submission form for the current subtask
      const submissionForm =
        currentSubtaskInfo.subtask_submission_form.submissionForm;

      // Retrieve the answers for the current subtask, default to an empty object if none
      const currentSubtaskAnswers =
        subtaskAnswers[currentSubtask.subtaskId] || {};

      // Check if every question in the submission form has an answer
      return submissionForm.every(
        (question) =>
          currentSubtaskAnswers[question.questionIndex] !== undefined
      );
    }

    if (!isCurrentSubtaskFilled() && taskType === 2) {
      alert("Please fill all the fields before submitting.");
      return;
    }

    const submissionData = {
      code: code,
      ...(taskType === 1 ? { personalDetails } : {}),
      answers: Object.entries(subtaskAnswers).map(
        ([subtaskId, subtaskAnswers]) => {
          const subtaskInfo = subtaskInfos.find(
            (info) => info.subtaskId === Number(subtaskId)
          );
          const submissionForm = subtaskInfo
            ? subtaskInfo.subtask_submission_form.submissionForm
            : [];

          return {
            subtaskId,
            submissionForm: submissionForm.map((question) => ({
              ...question,
              answer: subtaskAnswers[question.questionIndex] || "",
            })),
          };
        }
      ),
    };

    console.log(JSON.stringify(submissionData));
    axios
      .post(`${process.env.REACT_APP_BASE_URL}/submitLinkData`, {
        data: JSON.stringify(submissionData),
      })
      .then((response) => {
        console.log(response);
        setIsSubmitted(true);
        navigate(`/${code}/thanks`);
      })
      .catch((error) => console.error(`Error: ${error}`));
  };
  console.log(subtaskAnswers);

  function RedirectToPersonalDetail() {
    const navigate = useNavigate();
    const { code } = useParams();

    useEffect(() => {
      navigate(`/${code}/personal-detail`);
    }, [navigate, code]);

    return null;
  }

  function NotFound() {
    return (
      <div className=" h-screen flex justify-center items-center p-3">
        <div className="max-w-md shadow-xl rounded-xl bg-white border-2 border-boomcolor p-5 text-center">
          <img
            alt="crypanda"
            src="/crypanda.png"
            className="text-center mx-auto"
          />
          <h1 className="text-4xl py-2">404 - Not Found</h1>
          <p className="text-xl">
            The page you are looking for does not exist.
          </p>
        </div>
      </div>
    );
  }

  return (
    <div className="app-container">
      <SideNavbar
        taskType={taskType}
        subtaskInfos={subtaskInfos}
        onNavItemClick={handleNavItemClick}
        code={code}
      />
      <div className="content-container">
        <Routes>
          <Route path="*" element={<NotFound />} />
          {taskType === 1 ? (
            <>
              <Route
                path="/:code/:subtaskTitle"
                element={
                  currentSubtask &&
                  currentSubtask.subtask_submission_form && (
                    <SubmissionForm1
                      questions={
                        currentSubtask.subtask_submission_form.submissionForm
                      }
                      subtaskId={currentSubtask.subtaskId}
                      onAnswerChange={(subtaskId, questionIndex, answer) =>
                        handleAnswerChange(subtaskId, questionIndex, answer)
                      }
                    />
                  )
                }
              />
              <Route
                path="/:code/personal-detail"
                element={
                  <PersonalDetail
                    details={personalDetails}
                    onChange={handlePersonalDetailChange}
                    onSubmit={handleSubmit}
                    subtaskAnswers={subtaskAnswers}
                  />
                }
              />
              <Route path="/:code" element={<RedirectToPersonalDetail />} />
              {isSubmitted && (
                <Route path="/:code/thanks" element={<AfterSubmit />} />
              )}
            </>
          ) : (
            <>
              <Route
                path="/:code"
                element={
                  <SubmissionForm2
                    onNavItemClick={handleNavItemClick}
                    subtaskInfos={subtaskInfos}
                    onAnswerChange={(subtaskId, questionIndex, answer) =>
                      handleAnswerChange(subtaskId, questionIndex, answer)
                    }
                    handleSubmit={handleSubmit}
                  />
                }
              />
              <Route path="/:code/thanks" element={<AfterSubmit />} />
            </>
          )}
        </Routes>
        {taskType === 1 && !isSubmitted && (
          <button
            onClick={handleSubmit}
            className="btn btn-outline border-boomcolor rounded-full py-2 px-12 bg-white text-boomcolor hover:text-white hover:bg-boomcolor bottom-7"
          >
            Submit
          </button>
        )}
      </div>
    </div>
  );
}

export default AppRoutes;
