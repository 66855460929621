import React, { useEffect, useState } from 'react';

function PersonalDetail({ details, onChange, onSubmit, subtaskAnswers }) {
  const [formData, setFormData] = useState({
    name: '',
    email: '',
    phoneNumber: '',
  });

  useEffect(() => {
    setFormData(details);
  }, [details]);

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    const newFormData = { ...formData, [name]: value };
    setFormData(newFormData);
    onChange(newFormData);
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    onSubmit(subtaskAnswers); // Pass subtask answers to handleSubmit
  };

  return (
    <div>
      <div className='flex justify-center items-center w-full'>
        <div className='grid grid-cols-1 md:grid-cols-2 gap-4 justify-center items-center px-5 xl:px-5 py-5'>
          <div className='max-w-xl bg-cover bg-center md:flex'>
            <img src='/panda.svg' alt='login' className='' />
          </div>
          <div className='mx-auto w-full md:p-10 py-2 md:py-0 flex flex-col justify-center items-center'>
            <h1 className='text-center text-2xl sm:text-3xl font-semibold text-[#FF0455]'>
              Who's Submission Are you adding?
            </h1>
            <div className='w-full mt-5 md:mt-10'>
              <div className='mx-auto w-full sm:max-w-md md:max-w-lg flex flex-col gap-5 items-center justify-center'>
                <input
                  type='text'
                  name='name'
                  value={formData.name}
                  onChange={handleInputChange}
                  placeholder='Name'
                  className='input focus:border-boomcolor input-bordered w-full text-black placeholder:text-black/70 bg-white'
                />
                <input
                  type='email'
                  name='email'
                  value={formData.email}
                  onChange={handleInputChange}
                  placeholder='Enter Your Email'
                  className='input focus:border-boomcolor input-bordered w-full text-black placeholder:text-black/70 bg-white'
                />
                <input
                  type='tel'
                  name='phoneNumber'
                  value={formData.phoneNumber}
                  onChange={handleInputChange}
                  placeholder='Enter Your Phone No'
                  className='input focus:border-boomcolor input-bordered w-full text-black placeholder:text-black/70 bg-white'
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default PersonalDetail;
