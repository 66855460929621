import React, { useState } from "react";
import "./boxStyle.css";
import QuestionRenderer from "./QuestionRenderer";
import { Button } from "@mui/material";

function SubmissionForm2({
  subtaskInfos,
  onNavItemClick,
  onAnswerChange,
  handleSubmit,
}) {
  const [selectedSubtaskId, setSelectedSubtaskId] = useState(null);
  const [answers, setAnswers] = useState({});

  const handleItemClick = (subtask) => {
    onNavItemClick(subtask.subtaskId);
    setSelectedSubtaskId(subtask.subtaskId);
  };

  const handleAnswerChange = (questionIndex, answer) => {
    setAnswers((prevAnswers) => ({
      ...prevAnswers,
      [selectedSubtaskId]: {
        ...(prevAnswers[selectedSubtaskId] || {}),
        [questionIndex]: answer,
      },
    }));

    onAnswerChange(selectedSubtaskId, questionIndex, answer);
  };

  const handleCancelClick = () => {
    setSelectedSubtaskId(null);
  };

  const handleSubmitClick = () => {
    handleSubmit();
  };

  const selectedSubtask = subtaskInfos.find(
    (subtask) => subtask.subtaskId === selectedSubtaskId
  );
  const questions = selectedSubtask
    ? selectedSubtask.subtask_submission_form.submissionForm
    : [];

  return (
    <div className="">
      {!selectedSubtaskId && (
        <div className="flex justify-center items-center pt-10">
          <div className="grid grid-cols-1 md:grid-cols-2 justify-between  md:shadow-lg">
            <div className="w-full md:w-[500px]  md:h-96  md:flex justify-center">
              <img src="/panda.svg" alt="login" className="w-full" />
            </div>
            <div className="w-full md:p-10 py-2 md:py-0 flex flex-col gap-6 justify-center ">
              <h1 className="uppercase text-center text-3xl sm:text-3xl font-semibold text-[#FF0455]">
                Sub Tasks
              </h1>
              <div className=" flex flex-col gap-5  justify-center">
                {subtaskInfos.map((subtask, index) => (
                  <div
                    key={subtask.subtaskId}
                    onClick={() => handleItemClick(subtask)}
                    className=" flex justify-center w-full"
                  >
                    <h3 className="md:max-w-md w-80 rounded-lg text-lg md:text-xl border-2  border-gray-200  px-4 py-3   bg-white shadow-md">
                      {subtask.subtaskTitle}
                    </h3>
                  </div>
                ))}
              </div>
            </div>
          </div>
        </div>
      )}

      {selectedSubtaskId && (
        <div className="shadow-lg max-w-screen-xl p-5 my-5 flex flex-col gap-5 ">
          {questions.map((question, index) => (
            <div
              key={question.questionIndex}
              className="w-[300px]  md:w-[600px]"
            >
              <QuestionRenderer
                key={question.questionIndex}
                question={question}
                answer={
                  (answers[selectedSubtaskId] &&
                    answers[selectedSubtaskId][question.questionIndex]) ||
                  ""
                }
                onAnswerChange={(answer) =>
                  handleAnswerChange(question.questionIndex, answer)
                }
              />
            </div>
          ))}
          <div>
            <button
              className="border-2 me-4 border-boomcolor rounded-md py-1 px-4 hover:bg-boomcolor hover:text-white"
              onClick={handleCancelClick}
            >
              Cancel
            </button>
            <button
              className="border-2 border-boomcolor rounded-md py-1 px-4 hover:bg-boomcolor hover:text-white"
              //   endIcon={<SendIcon />}
              onClick={handleSubmitClick}
            >
              Submit Form
            </button>
          </div>
        </div>
      )}
    </div>
  );
}

export default SubmissionForm2;
