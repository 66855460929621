import React from 'react'
import { useNavigate, useParams } from 'react-router-dom'
import { Button } from '@mui/material';

function AfterSubmit() {
  const navigate = useNavigate();
  const { code } = useParams();

  const handleHomeClick = () => {
    navigate(`/${code}`);
  };

  return (
    <div>
      <h1>Thank You!</h1>
      <p>Your form has been successfully submitted.</p>
      <p>To make another submission, click below</p>
      <div style={{ display: 'flex', justifyContent: 'center' }}>
        <Button variant="contained" color="primary" onClick={handleHomeClick}>
          Home
        </Button>
      </div>
    </div>
  )
}

export default AfterSubmit